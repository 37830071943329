//
// Co3
//
// Copyright:: (c) 2024 Innotronic Ingenieurbüro GmbH

import { Controller } from "@hotwired/stimulus"
import * as bootstrap from 'bootstrap';

export default class extends Controller
{
  static values = { content: String }

  connect()
  {
    this.popover = new bootstrap.Popover( this.element, { content: this.content } );
  }

  disconnect()
  {
    this.popover.dispose();
    this.popover = null;
  }

  get content()
  {
    if( this.hasContentValue ) return this.contentValue;
    if( this.element.hasAttribute( 'title' )) return this.element.getAttribute( 'title' );
    if( 'bs-content' in this.element.dataset ) return this.element.dataset.bsContent;
  }
}