//
// Co3
//
// Copyright:: (c) 2024 Innotronic Ingenieurbüro GmbH

// Hotwire
import "@hotwired/turbo-rails";

// Stimulus controllers
import "./controllers";

// Bootstrap
import * as bootstrap from "bootstrap";
document.addEventListener( 'turbo:load', e =>
{
  document.querySelectorAll( '[data-bs-toggle="popover"]' ).forEach( elt => { new bootstrap.Popover( elt ); } );
});

// Font Awesome
import '@fortawesome/fontawesome-free/js/all';

// Action Text
import "trix"
import "@rails/actiontext"