//
// Co3
//
// Copyright:: (c) 2024 Innotronic Ingenieurbüro GmbH

import { application } from "./application"

import BenutzerPasswordController from "./benutzer_password_controller"
application.register( 'benutzer-password', BenutzerPasswordController )

import DialogController from "./dialog_controller"
application.register( 'dialog', DialogController )

import LinkController from "./link_controller"
application.register( 'link', LinkController )

import NotificationController from "./notification_controller"
application.register( 'notification', NotificationController )

import PopoverController from "./popover_controller"
application.register( 'popover', PopoverController )

import SelectController from "./select_controller"
application.register( 'select', SelectController )

import TabController from "./tab_controller"
application.register( 'tab', TabController )

import ZeitDauerController from "./zeit_dauer_controller"
application.register( 'zeit-dauer', ZeitDauerController )
